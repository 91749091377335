import request from '@/utils/request'
// 获取小程序配置图片列表
export function getImgList(data) {
  return request('post', '/apm/shop/ad/list', data)
}

// 上传轮播图
export function getImgEdit(data) {
  return request('post', '/apm/shop/ad/mod', data)
}

// 轮播图排序
export function getImgChange(data) {
  return request('post', '/apm/shop/ad/change', data)
}

// 删除轮比兔
export function getImgDel(data) {
  return request('post', '/apm/shop/ad/del', data)
}

// 门店wifi列表
export function getWifiList(data) {
  return request('post', '/apm/shop/wifi/list', data)
}

// 门店wifi编辑
export function getWifiEdit(data) {
  return request('post', '/apm/shop/wifi/mod', data)
}

// 富文本查询
export function getEditorInfo(data) {
  return request('post', '/apm/shop/tips/findById', data)
}

// 富文本保存
export function getEditorEdit(data) {
  return request('post', '/apm/shop/tips/mod', data)
}