<template>
  <div class="mini-app">
    <el-tabs v-model="activeName" @tab-click="onTabs">
      <!-- 门店轮播图 -->
      <el-tab-pane label="门店轮播图" name="1">
        <li v-for="(item, index) in imgList" class="img-list" :key="index">
          <el-image
            class="img-item"
            :src="item.adPicUrl"
            :preview-src-list="[item.adPicUrl]"
          >
          </el-image>
          <div class="img-edit">
            <i class="el-icon-edit" @click="onReplace(item)"></i>
            <i class="el-icon-top" @click="onUp(item)" v-if="index != 0"></i>
            <i
              class="el-icon-bottom"
              @click="onDown(item)"
              v-if="index != imgList.length - 1"
            ></i>
            <i class="el-icon-delete" @click="onDel(item)"></i>
          </div>
        </li>
        <div class="upload-btn" @click="onUpload('轮播图')">
          <i class="el-icon-plus uploader-icon"></i>
        </div>
      </el-tab-pane>
      <!-- WIFI配置 -->
      <el-tab-pane label="WIFI配置" name="2">
        <el-form :model="wifiData" :rules="rules" ref="formRef" inline>
          <li v-for="(item, index) in wifiData.wifiList">
            <el-form-item
              label="wifi名称"
              :prop="'wifiList.' + index + '.wifiName'"
              :rules="rules.wifiName"
            >
              <el-input v-model.trim="item.wifiName" maxlength="16"></el-input>
            </el-form-item>
            <el-form-item
              label="wifi密码"
              :prop="'wifiList.' + index + '.wifiPassword'"
              :rules="rules.wifiPassword"
            >
              <el-input
                v-model.trim="item.wifiPassword"
                maxlength="16"
              ></el-input>
            </el-form-item>
            <el-button @click.prevent="onWifiDel(item)">删除</el-button>
          </li>
          <div class="wifi-footer">
            <el-button @click="onWifiAdd()">新增WiFi</el-button>
            <el-button
              type="primary"
              v-if="wifiData.wifiList.length"
              @click="onWifiSave('formRef')"
              >保存</el-button
            >
          </div>
        </el-form>
      </el-tab-pane>
      <!-- 常见问题 -->
      <el-tab-pane label="常见问题" name="3">
        <quill-editor
          v-model="content"
          ref="myQuillEditor"
          :options="editorOption"
          @change="onEditorChange($event)"
        >
        </quill-editor>
        <div class="editor-footer">
          <el-button type="primary" @click="onEditor()">保存</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 图片上传组件 -->
    <UpLoad @fileIds="getFileIds" ref="up" :fileType="1"></UpLoad>
  </div>
</template>

<script>
import UpLoad from "@/components/upload";
import { getFileList } from "@/api/common";
import {
  getImgList,
  getImgEdit,
  getImgChange,
  getImgDel,
  getWifiList,
  getWifiEdit,
  getEditorInfo,
  getEditorEdit,
} from "@/api/shop/miniApp";
import { rules } from "@/db/rules";

import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import * as Quill from "quill";
// 调整上传图片大小
import ImageResize from "quill-image-resize-module";
Quill.register("modules/imageResize", ImageResize);

export default {
  components: { UpLoad, quillEditor },
  data() {
    return {
      rules,
      activeName: "1",
      imgList: [], //图片列表
      uploadTitle: "",
      params: {
        shopId: "", //店铺ID
        adId: "", //轮播ID
        adPic: "", //图片id集合
      },
      wifiData: {
        wifiList: [
          {
            wifiName: "",
            wifiPassword: "",
          },
        ],
      },
      content: "",
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
              ["blockquote", "code-block"], // 引用  代码块
              [{ header: 1 }, { header: 2 }], // 1、2 级标题
              [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
              [{ script: "sub" }, { script: "super" }], // 上标/下标
              [{ indent: "-1" }, { indent: "+1" }], // 缩进
              [{ direction: "rtl" }], // 文本方向
              [
                {
                  size: [
                    "12",
                    "14",
                    "16",
                    "18",
                    "20",
                    "22",
                    "24",
                    "28",
                    "32",
                    "36",
                  ],
                },
              ], // 字体大小
              [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
              [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
              // [{ font: ['songti'] }], // 字体种类
              [{ align: [] }], // 对齐方式
              ["clean"], // 清除文本格式
              ["image", "video"], // 链接、图片、视频
            ],
            handlers: {
              image: (value) => {
                if (value) {
                  this.onUpload("富文本");
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
          // 调整图片大小
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
        },
        placeholder: "请填写正文",
      },
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.params.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getImgList();
    this.getWifiList();
    this.getEditorInfo();
  },
  methods: {
    // 【请求】小程序轮播图列表
    getImgList() {
      let data = {
        shopId: this.params.shopId,
      };
      getImgList(data).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            this.imgList = res.data;
          }
        }
      });
    },

    // 【请求】小程序wifi列表
    getWifiList() {
      let data = {
        shopId: this.params.shopId,
      };
      getWifiList(data).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            this.wifiData.wifiList = res.data;
          }
        }
      });
    },

    // 【请求】富文本信息
    getEditorInfo() {
      let data = {
        shopId: this.params.shopId,
      };
      getEditorInfo(data).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            this.content = res.data.tipsContent;
          }
        }
      });
    },

    // 【请求】小程序轮播图上传
    getImgEdit() {
      let data = this.params;
      getImgEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.getImgList();
        }
      });
    },

    // 【请求】小程序wifi编辑
    getWifiEdit() {
      let data = this.wifiData.wifiList;
      getWifiEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getWifiList();
        }
      });
    },

    // 【请求】富文本保存
    getEditorEdit() {
      let data = {
        shopId: this.params.shopId,
        tipsContent: this.content,
      };
      getEditorEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getEditorInfo();
        }
      });
    },

    // 【监听】菜单切换
    onTabs(tab, event) {},

    cutUrl(url) {
      let index = url.indexOf("?");
      return url.slice(0, index);
    },

    // 【请求】获取文件信息
    getFileList(fileIds) {
      let data = {
        fileIds,
      };
      getFileList(data).then((res) => {
        if (res.isSuccess == "yes") {
          let url = this.cutUrl(res.data[0].url); //获取图片路径
          console.log("url: ", url);
          let quill = this.$refs.myQuillEditor.quill; //获取光标信息
          let length = quill.getSelection().index; //获取当前光标位置
          quill.insertEmbed(length, "image", url); //在光标处插入图片
          quill.setSelection(length + 1); //将光标移至图片后
        } else {
          this.$message.error("图片插入失败！");
        }
      });
    },

    // 【监听】获取上传文件ID集合
    getFileIds(fileIds) {
      if (this.uploadTitle == "轮播图") {
        this.params.adPic = fileIds;
        this.getImgEdit();
      } else if (this.uploadTitle == "富文本") {
        this.getFileList(fileIds);
      }
    },

    // 【监听】文件上传
    onUpload(title) {
      this.uploadTitle = title;
      if (this.uploadTitle == "轮播图") {
        this.params = {
          adId: "", //轮播ID
          adPic: "", //图片id集合
          shopId: this.params.shopId, //店铺ID
        };
      }
      this.$refs.up.onUpload();
    },

    // 【监听】图片编辑
    onReplace(row) {
      this.params = JSON.parse(JSON.stringify(row));
      this.$refs.up.onUpload();
    },

    // 【监听】图片上移
    onUp(row) {
      row.changeType = 1;
      this.$confirm("你确定要上移吗?", "上移", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        getImgChange(row).then((res) => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.getImgList();
          }
        });
      });
    },

    // 【监听】图片下移
    onDown(row) {
      row.changeType = 2;
      this.$confirm("你确定要下移吗?", "下移", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        getImgChange(row).then((res) => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.getImgList();
          }
        });
      });
    },

    // 【监听】图片删除
    onDel(row) {
      this.$confirm("你确定要删除吗?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        getImgDel(row).then((res) => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.getImgList();
          }
        });
      });
    },

    // 【监听】保存wifi信息
    onWifiSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getWifiEdit();
        }
      });
    },

    // 【监听】wifi删除
    onWifiDel(item) {
      var index = this.wifiData.wifiList.indexOf(item);
      if (index !== -1) {
        this.wifiData.wifiList.splice(index, 1);
      }
    },

    // 【监听】新增WiFi
    onWifiAdd() {
      let obj = {
        shopId: this.params.shopId,
        wifiName: "",
        wifiPassword: "",
      };
      this.wifiData.wifiList.push(obj);
    },

    // 内容改变事件
    onEditorChange({ quill, html, text }) {
      this.content = html;
    },

    // 【监听】保存富文本
    onEditor() {
      this.getEditorEdit();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";

.mini-app {
  padding: 0.15rem;
}

.upload-btn {
  border: 1px dashed $theme-color;
  border-radius: 6px;
  cursor: pointer;
  width: 4rem;
  height: 1.5rem;
}

.upload-btn:hover {
  border-color: $theme-color;
}

.uploader-icon {
  font-size: 28px;
  color: $theme-color;
  width: 4rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
}

.img-list {
  margin: 0.1rem 0;
  display: flex;
}

.img-edit {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: 0.2rem;
  width: 2.5rem;

  > i {
    font-size: 0.2rem;
    cursor: pointer;
  }
}

.img-edit > i:hover {
  color: $theme-color;
}

.img-item {
  width: 4rem;
  height: 1.5rem;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
}

.foot-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.1rem;
}

.editor-footer {
  margin-top: 0.15rem;
  display: flex;
}
</style>

<style>
.ql-container {
  min-height: 4rem;
}
</style>

